import React, { useEffect, useState } from "react";
// import "../../styles/Home.css";
// import TextBanner from "../../images/Home/Text Banner.png";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
// import OrangeBand from "../../images/Home/Orange Band2.png";
// import Footer from "../../common/Footer";
import Header from "../../common/Header";
// import Banner from "../../images/testBanner.svg";
// import gogoDollBanner from "../../images/gogoDollBanner.svg";
// import greenside from "../../images/side green vines .svg";
import Footer from "../../common/Footer/index";

import { useTheme } from "@emotion/react";
import { Helmet } from "react-helmet";
import clevertap from "clevertap-web-sdk";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";

import SideCurtains from "../../common/Header/sidecurtain";
import { Sponsors } from "../../common/Footer/sponsors";
import StyledTypography from "../../font/StyledTExt";
import { ImageBox } from "../tickets";


export const DividerBox = ({ imageSrc, alt = "Divider" }) => {
  return (
    <Box
      component="img"
      src={imageSrc}
      alt={alt}
      sx={{
        width: {
          xs: "220px",
          sm: "240px",
          md: "260px",
          lg: "338px",
          xl: "350px",
        },
        height: "auto",
        display: "block",
        margin: "auto",
      }}
    />
  );
};

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const windowWidth = document.documentElement.clientWidth;
  const windowHeight = document.documentElement.clientHeight;
  console.log("windowWidth", windowWidth);
  console.log("windowHeight", windowHeight);
  
  const theme = useTheme();


  // to handle slider images popup


  // to handle video popup


 


  useEffect(() => {
    //GTM
    window.scrollTo(0, 0);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      event_action: "view",
      name: "home_page",
    });
    //clevertap
    clevertap.event.push("Bandland PageView");
  }, []);
  // const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // // Function to update the screen height
  // const updateScreenHeight = () => {
  //     setScreenHeight(window.innerHeight);
  //     console.log('window:', window.innerHeight)
  // };

  //     const [headerImagesLoaded, setHeaderImagesLoaded] = useState(false);

  //   const handleHeaderImagesLoad = () => {
  //     setHeaderImagesLoaded(true);
  //   };

  const pageContent = (
    <Box
      position="absolute"
      sx={
        {
          
          marginTop: {
            xs: "55px",
            sm: "80px",
            md: "90px",
            lg: "120px",
            xl: "120px",
            xll: "120px",
          },
        }
      }
      width="100%"
    >
      <SideCurtains
        leftImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesleft/home.webp"
        rightImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesright/home.webp"
      />
   
   {/* <StyledTypography
        fontFamily="modesto-condensed"
        sx={{
          lineHeight: { xs: '34px', sm: '38px', md: '52px', lg: '60px', xl: '60px' },
          fontSize: { xs: '30px', sm: '36px', md: '48px', lg: '56px', xl: '56px' },
          paddingTop: { xs: '67px', sm: '76px', md: '85px', lg: '125px', xl: '125px' },
          paddingBottom: { xs: '16px', sm: '19px', md: '28px', lg: '34px', xl: '34px' },
          fontWeight: 'bold',
        }}
      >
        EARLY BIRD TICKETS OUT NOW!
      </StyledTypography> */}
      
 <Button   variant="contained" sx={{ paddingX:{
  xs:"26px", sm:"28px",md:"30px", lg:"45px", xl:"45px"
 } ,fontSize:{
  xs:"12px", sm:"14px",md:"18px", lg:"18px", xl:"18px"
 },
 fontWeight:"600"
 ,paddingY:{
  xs:"6px", sm:"8px",md:"10px", lg:"10px", xl:"10px"
 } ,backgroundColor:"#fc0e49", marginBottom:{
   xs:"15px", sm:"15px",md:"30px", lg:"40px", xl:"45px"
 },
 borderRadius:{
  xs:"5px", sm:"6px",md:"6px", lg:"7px", xl:"8px"
 },
 '&:hover': {
  backgroundColor: "#e00d43", // Slightly darker color for hover state
}
 }

 }  onClick={() => window.open('https://in.bookmyshow.com/events/bandland-2024/ET00406791', '_blank')}> Book Now </Button>


      {/* <DividerBox
        imageSrc={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/common/dividerline.webp"
        }
      /> */}
      <div
      ></div>

<ImageBox src={"/LineUpLatest.png"} alt={"LineUp"} pt={false} pb={false} width={ {smallest: 330, xs: 330 ,
          sm: 420, 
          md:885,
          lg: 885,
          xl: 885}} marginYaxis={true} marginY={{xs: "0%" ,
            sm: "0%", 
            md:"0%",
            lg: "0%",
            xl: "0%"}} />
      {/* <StyledTypography
        fontFamily="modesto-expanded"
        sx={{
          height: { xs: 'auto', sm: 'auto', md: '85px', lg: '120px', xl: '120px' },
          fontSize: { xs: '26px', sm: '30px', md: '36px', lg: '48px', xl: '48px' },
          paddingBottom: { xs: '27px', sm: '18px', md: '18px', lg: '18px', xl: '18px' },
          paddingTop: { xs: '35px', sm: '40px', md: '55px', lg: '78px', xl: '78px' },
          lineHeight: 1,
        }}
      >
        LIFE IS CRAZY <br />
        ENOUGH AS IT IS
      </StyledTypography>

      <StyledTypography
        fontFamily="modesto-expanded"
        sx={{
          height: { xs: 'auto', sm: 'auto', md: '85px', lg: '120px', xl: '120px' },
          maxWidth: { xs: '293px', sm: '800px', md: '800px', lg: '800px', xl: '800px' },
          fontSize: { xs: '26px', sm: '30px', md: '36px', lg: '48px', xl: '48px' },
          paddingBottom: { xs: '23px', sm: '22px', md: '20px', lg: '18px', xl: '18.5px' },
          lineHeight: 1,
          fontWeight: 'bold',
        }}
      >
        COME BACK TO WHAT YOU <br />
        KNOW AND LOVE
      </StyledTypography>

      <StyledTypography
        fontFamily="texturina"
        sx={{
          mt: { xs: 1, lg: 10 },
          fontSize: { xs: '27px', sm: '30px', md: '40px', lg: '70px', xl: '70px' },
          lineHeight: 1,
          fontWeight: 'semibold',
          marginBottom: '0px',
          paddingBottom: '0px',
        }}
      >
        COME BACK TO
      </StyledTypography>

      <StyledTypography
        fontFamily="modesto-condensed"
        sx={{
          fontSize: { xs: '50px', sm: '70px', md: '80px', lg: '100px', xl: '100px' },
          lineHeight: 1,
          fontWeight: 'bold',
          marginTop: '0px',
          paddingTop: '0px',
        }}
      >
        BANDLAND!
      </StyledTypography>
    */}

      <Box position="relative" bottom="0" marginTop="auto">
        <Sponsors
          svgSrc={
            "/homeSponsors.png"
          }
          mb={5}
        />
        <Footer />
      </Box>
    </Box>
  );

  return (
    <Box>
      <Helmet>
        <title>
          Bandland 2024 Rock's One True Homecoming Back in Bengaluru
        </title>
        
        <link rel="canonical" href="https://www.bandland.in/#/" />
      
        <meta
      property="og:title"
      content="Bandland 2024 | Revel In The Best of Bangalore’s Live Music"
    />
    <meta
      property="og:description"
      content="Relive the Golden Era of rock music festivals at the Badland Rock Festival in Bengaluru. Get your tickets now!"
    /> 
     <meta
      name="title"
      content="Bandland 2024 Rock's One True Homecoming Back in Bengaluru"
    />
        <meta
          name="description"
          content="Relive the Golden Era of rock music festivals at the Badland Rock Festival in Bengaluru. Get your tickets now!"
        />
        <meta
          name="keywords"
          content="Bandland, Bandland 2024, Bandland concert, Bandland party, Bandland Bangalore, Bandland tickets, Bandland 2024, Bandland Bangalore tickets"
        />
        
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NNK87PBS');
                    `}
        </script>
        <script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    "url": "https://www.bandland.in/",
    "logo": "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/mobile/common/footerLogo.web",
    "name": "Bandland",
    "sameAs": [

                 
      "https://www.facebook.com/Bandland.in/",
      "https://twitter.com/BandlandIndia/",
      "https://www.youtube.com/@BandlandIndia ",
     "https://www.instagram.com/bandland.in/"
    ],
    "contactPoint": [{
      "@type": "ContactPoint",
      "emaildId": "CustomerExperienceForce@bookmyshow.com",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": ["English", "Hindi", "Marathi", "Tamil", "Telugu", "Kannada", "Gujarati", "Punjabi"]
    }] 
  })
}
</script>
{/* website */}
<script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "BookMyShow",
    "url": "https://www.bandland.in/"
  })
}
</script>
{/* breadcrumnb */}
<script type="application/ld+json">
{
  JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://example.com/dresses",
          "name": "Dresses"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": "https://example.com/dresses/real",
          "name": "Real Dresses"
        }
      }
    ]
  })
}
</script>


{/* music event */}
<script type="application/ld+json">
{
  JSON.stringify([
    {
      "@context": "http://schema.org",
      "@type": "MusicEvent",
      "name": "Bandland 2024",
      "image": "https://in.bmscdn.com/events/showcasesynopsis/ET00076572.jpg",
      "url": "https://in.bookmyshow.com/events/bandland-2024/ET00406791",
      "description": "Event-Synopsis",
      "startDate": "2024-11-12T19:00:00+0530",
      "endDate": "2024-11-12T22:00:00+0530",
      "doorTime": "19:00",
      "location": {
        "@type": "Place",
        "name": "Bangaluru",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Behind Gandhi Market, Comrade Harbanslal Marg, Sion",
          "addressLocality": "Mumbai",
          "addressCountry": "Bangalore"
        }
      },
      "offers": [
        {
          "@type": "Offer",
          "name": "Second Floor Row A to H",
          "price": "500",
          "priceCurrency": "INR",
          "validFrom": "2018-08-12", //Event date
          "availability": "http://schema.org/InStock",
          "url": "https://in.bookmyshow.com/mumbai/events/shreya-ghoshal-with-symphony-orchestra/ET00076572"
        },
        {
          "@type": "Offer",
          "name": "First Floor Row H to M",
          "price": "885",
          "priceCurrency": "INR",
          "validFrom": "2018-08-12", //Event date
          "availability": "Available",
          "url": "https://in.bookmyshow.com/mumbai/events/shreya-ghoshal-with-symphony-orchestra/ET00076572"
        }
      ],
      "performer": [
        {
          "@type": "MusicGroup",
          "name": "Paradigm-shift",
          "sameAs": "https://in.bookmyshow.com/person/paradigm-shift/1048806"
        }
      ]
    }
  ])
}
</script>




        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNK87PBS"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>
      <Header
        gogoDollBanner={
          "https://bandland.s3.ap-south-1.amazonaws.com/images/gogoDollBanner.svg"
        }
        pageContent={pageContent}
      />
    </Box>
  );
};

export default Home;
